import React, { useState, useEffect } from "react";
import { set, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";

const AddProductForm = ({
  handleFromClose,
  isEditMode,
  productData,
  refreshProducts,
}) => {
  const { register, handleSubmit, reset } = useForm();
  const [imagePreviews, setImagePreviews] = useState({});
  const [imageFiles, setImageFiles] = useState({});
  const [loading, setLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);

  const token = localStorage.getItem("adminToken");

  useEffect(() => {
    const fetchCategoryOptions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_API_URL}/api/product/category-images`
        );
        const data = await response.json();
        if (data.success) {
          setCategoryOptions(data.data);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategoryOptions();
  }, []);

  useEffect(() => {
    if (isEditMode && productData) {
      reset(productData);
    } else {
      reset();
    }
  }, [isEditMode, productData, reset]);

  const capitalizeFirstLetter = (text) => {
    if (!text) return "";
    return text
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const toLowerCase = (text) => {
    return text ? text.toLowerCase() : "";
  };

  const handleImageUpload = (e) => {
    const { id } = e.target;
    const file = e.target.files[0];

    if (file) {
      const previewURL = URL.createObjectURL(file);

      setImageFiles((prev) => ({ ...prev, [id]: file }));
      setImagePreviews((prev) => ({ ...prev, [id]: previewURL }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    setLoading(true);
  
    const formData = new FormData();
  
    Object.entries(imageFiles).forEach(([fieldName, file]) => {
      formData.append(fieldName, file);
    });
  
    const highlights = e.target.highlights.value
      .split(",")
      .map((item) => item.trim());
  
    const productName = capitalizeFirstLetter(e.target.product_name.value);
    const description = capitalizeFirstLetter(e.target.description.value);
    const details = capitalizeFirstLetter(e.target.details.value);
    const category = toLowerCase(e.target.category.value);
  
    formData.append("product_name", productName);
    formData.append("category", category);
    formData.append("product_price", e.target.product_price.value);
    
    const productPrice = parseFloat(e.target.product_price.value);
    const actualPrice = parseFloat(e.target.actual_price.value);
    const discount = Math.round(((productPrice - actualPrice) / productPrice) * 100);
    formData.append("discount", discount);
    
    formData.append("actual_price", e.target.actual_price.value);
    formData.append("description", description);
    formData.append("highlights", JSON.stringify(highlights));
    formData.append("details", details);
  
    // Determine if we are adding or editing
    const isEditMode = !!productData;
  
    try {
      const url = isEditMode
        ? `${process.env.REACT_APP_BACKEND_API_URL}/api/product/update-product/${productData.product_id}`
        : `${process.env.REACT_APP_BACKEND_API_URL}/api/product/add-product`;
  
      const method = isEditMode ? "PUT" : "POST";
  
      const response = await fetch(url, {
        method: method,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
  
      // Check for HTTP error status codes
      if (!response.ok) {
        const errorMessage = await response.text(); // Capture error message if any
  
        alert(
          isEditMode ? "Failed to update product" : "Failed to add product"
        );
        return;
      }
  
      alert(
        isEditMode
          ? "Product updated successfully"
          : "Product added successfully"
      );
      refreshProducts();
      handleFromClose();
    } catch (error) {
      alert(
        isEditMode
          ? "Failed to update product. Try again later"
          : "Failed to add product. Try again later"
      );
    } finally {
      setLoading(false); // Ensure loading is set to false
    }
  };
  

  return (
    <div className="w-full">
      {loading && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50">
          <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
        </div>
      )}
      <div className="flex items-center justify-between w-full">
        <h2 className="mb-4 text-2xl font-bold">Add Product</h2>
        <IoMdClose
          size={30}
          className="mb-4 cursor-pointer"
          onClick={handleFromClose}
        />
      </div>
      <form
        onSubmit={handleFormSubmit}
        method="POST"
        encType="multipart/form-data"
        className="space-y-6"
      >
        {/* Product Title and Category */}
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div>
            <label htmlFor="product_name" className="block font-medium">
              Product Title *
            </label>
            <input
              type="text"
              id="product_name"
              name="product_name"
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              defaultValue={
                isEditMode && productData ? productData.product_name : ""
              }
              required
            />
          </div>
          <div>
            <label htmlFor="category" className="block font-medium">
              Category *
            </label>

            <select
              id="category"
              name="category"
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              defaultValue={
                isEditMode && productData ? productData.category : ""
              }
              required
            >
              <option value="">Select Category</option>
              {categoryOptions.length > 0
                ? categoryOptions.map((category, index) => (
                    <option value={category.category_name} key={index}>
                      {" "}
                      {/* Use category_name as value */}
                      {category.category_name}
                    </option>
                  ))
                : null}
            </select>

            {categoryOptions.length === 0 && (
              <p className="mt-2 text-red-500">
                No categories found. Please add category first.
              </p>
            )}
          </div>
        </div>

        {/* Product Price, Actual Price and Discount */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="product_price" className="block font-medium">
              Product Price *
            </label>
            <input
              type="number"
              id="product_price"
              name="product_price"
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              defaultValue={
                isEditMode && productData ? productData.product_price : ""
              }
              required
            />
          </div>
          {/* <div>
            <label htmlFor="discount" className="block font-medium">
              Discount (%) *
            </label>
            <input
              type="number"
              id="discount"
              name="discount"
              required
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              defaultValue={
                isEditMode && productData ? productData.discount : ""
              }
            />
          </div> */}
          <div>
            <label htmlFor="actual_price" className="block font-medium">
              Actual Price (Price after discount) *
            </label>
            <input
              type="number"
              id="actual_price"
              name="actual_price"
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              defaultValue={
                isEditMode && productData ? productData.actual_price : ""
              }
              required
            />
          </div>
        </div>

        {/* Main Image and Additional Images */}
        <>
          <div className="flex gap-5">
            <label className="block font-medium">Upload Images</label>
            <span className="text-red-500 opacity-70">
              (Size: 3MB max, Resolution: 1333x2000)
            </span>
          </div>
          <div className="grid grid-cols-1 space-y-2 lg:grid-cols-4">
            <div>
              <label htmlFor="main_image" className="block ">
                Main Image {isEditMode ? "" : "*"}
              </label>
              <input
                onChange={handleImageUpload}
                type="file"
                id="main_image"
                name="main_image"
                accept="image/*"
                className="w-full"
                required={!isEditMode} // Required only when adding
              />
              {(imagePreviews.main_image ||
                (isEditMode && productData.main_image)) && (
                <div className="mt-4">
                  <img
                    src={imagePreviews.main_image || productData.main_image}
                    alt="Main Image Preview"
                    className="object-cover w-32 h-32"
                  />
                </div>
              )}
            </div>
            {/* Repeat for other images */}
            <div>
              <label htmlFor="image_1" className="block">
                Image 1
              </label>
              <input
                onChange={handleImageUpload}
                type="file"
                id="image_1"
                name="image_1"
                accept="image/*"
                className="w-full"
              />
              {(imagePreviews.image_1 ||
                (isEditMode && productData.image_1)) && (
                <div className="mt-4">
                  <img
                    src={imagePreviews.image_1 || productData.image_1}
                    alt="Image 1 Preview"
                    className="object-cover w-32 h-32"
                  />
                </div>
              )}
            </div>
            <div>
              <label htmlFor="image_2" className="block">
                Image 2
              </label>
              <input
                onChange={handleImageUpload}
                type="file"
                id="image_2"
                name="image_2"
                accept="image/*"
                className="w-full"
              />
              {(imagePreviews.image_2 ||
                (isEditMode && productData.image_2)) && (
                <div className="mt-4">
                  <img
                    src={imagePreviews.image_2 || productData.image_2}
                    alt="Image 2 Preview"
                    className="object-cover w-32 h-32"
                  />
                </div>
              )}
            </div>
            <div>
              <label htmlFor="image_3" className="block">
                Image 3
              </label>
              <input
                onChange={handleImageUpload}
                type="file"
                id="image_3"
                name="image_3"
                accept="image/*"
                className="w-full"
              />
              {(imagePreviews.image_3 ||
                (isEditMode && productData.image_3)) && (
                <div className="mt-4">
                  <img
                    src={imagePreviews.image_3 || productData.image_3}
                    alt="Image 3 Preview"
                    className="object-cover w-32 h-32"
                  />
                </div>
              )}
            </div>
          </div>
        </>

        {/* Description */}
        <div>
          <label htmlFor="description" className="block font-medium">
            Description *
          </label>
          <textarea
            id="description"
            name="description"
            className="w-full p-2 mt-1 border border-gray-300 rounded resize-none"
            defaultValue={
              isEditMode && productData ? productData.description : ""
            }
            rows="4"
            required
          ></textarea>
        </div>

        {/* Highlights & Details */}
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div>
            <label htmlFor="highlights" className="block font-medium">
              Highlights *
            </label>
            <textarea
              id="highlights"
              name="highlights"
              required
              className="w-full p-2 mt-1 border border-gray-300 rounded resize-none"
              rows="4"
              defaultValue={
                isEditMode && productData ? productData.highlights : ""
              }
              placeholder="Please separate each highlight with a comma (,)
              e.g. Highlight 1, Highlight 2, Highlight 3"
            ></textarea>
          </div>

          <div>
            <label htmlFor="details" className="block font-medium">
              Details
            </label>
            <textarea
              id="details"
              name="details"
              defaultValue={
                isEditMode && productData ? productData.details : ""
              }
              className="w-full p-2 mt-1 border border-gray-300 rounded resize-none"
              rows="4"
              placeholder="Additional product details"
            ></textarea>
          </div>
        </div>

        {/* Submit Button */}
        <div className="">
          <button
            type="submit"
            className="w-full py-2 font-semibold text-[18px] text-white bg-[#E85826] hover:bg-[#A5320C]"
          >
            {isEditMode ? "Update Product" : "Add Product"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProductForm;
