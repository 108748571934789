import React, { useState } from "react";
import logo from "../../Assets/images/logo.png";
import { IoMdClose } from "react-icons/io";
// import { TfiRuler } from "react-icons/tfi";

const RegisterLogin = ({ togglePop }) => {
  const [activeSection, setActiveSection] = useState("signin");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisible] = useState(false);
  const [PopUpMessage, setPopUpMessage] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  // Updated Password validation function:
  // Now it only requires the password to be at least 4 characters.
  const validatePassword = (password) => {
    return password.length >= 4;
  };

  const handleSignUp = async (e) => {
    e.preventDefault();

    const firstName = document.getElementById("firstName").value;
    const lastName = document.getElementById("lastName").value;
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    // Password validation
    if (!validatePassword(password)) {
      setAlertMessage("Password must be at least 4 characters.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setTimeout(() => setAlertMessage(""), 300);
      }, 3000);
      return;
    }

    const userData = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();
      if (response.status === 201) {
        localStorage.setItem("token", data.token);
        setPopUpMessage(
          <p className="text-green-400 text-[18px]">
            ✅ You have successfully registered
          </p>
        );
        setShowPopUp(true);
        setTimeout(() => {
          setShowPopUp(false);
          setTimeout(() => setPopUpMessage(""), 3000);
        }, 3000);
        setTimeout(() => {
          togglePop();
        }, 3000);
      } else {
        setPopUpMessage(
          <p className="text-yellow-600 text-[18px]">
            User already exists. Please Sign In
          </p>
        );
        setShowPopUp(true);
        setTimeout(() => {
          setShowPopUp(false);
          setTimeout(() => setPopUpMessage(""), 3000);
        }, 3000);
        setTimeout(() => {
          setActiveSection("signin");
        }, 3000);
      }
    } catch (error) {
      setPopUpMessage(
        <p className="text-red-600 text-[18px]">
          Something went wrong. Please try again
        </p>
      );
      setShowPopUp(true);
      setTimeout(() => {
        setShowPopUp(false);
        setTimeout(() => setPopUpMessage(""), 3000);
      }, 3000);
    }
  };

  const hadnleSignIn = async (e) => {
    e.preventDefault();

    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    // Password validation
    if (!validatePassword(password)) {
      setAlertMessage("Password must be at least 4 characters.");
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setTimeout(() => setAlertMessage(""), 300);
      }, 3000);
      return;
    }

    const userData = {
      email,
      password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/api/user/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        localStorage.setItem("token", data.token);
        setPopUpMessage(
          <p className="text-green-400 text-[18px]">
            ✅ You have successfully logged in
          </p>
        );
        setShowPopUp(true);
        setTimeout(() => {
          setShowPopUp(false);
          setTimeout(() => setPopUpMessage(""), 3000);
        }, 3000);
        setTimeout(() => {
          togglePop();
        }, 3000);
      } else {
        setPopUpMessage(
          <p className="text-yellow-600 text-[18px]">
            Invalid Credentials. Please try again.
          </p>
        );
        setShowPopUp(true);
        setTimeout(() => {
          setShowPopUp(false);
          setTimeout(() => setPopUpMessage(""), 3000);
        }, 3000);
        setTimeout(() => {
          setActiveSection("signin");
        }, 3000);
      }
    } catch (error) {
      setPopUpMessage(
        <p className="text-red-600 text-[18px]">
          Something went wrong. Please try again
        </p>
      );
      setShowPopUp(true);
      setTimeout(() => {
        setShowPopUp(false);
        setTimeout(() => setPopUpMessage(""), 3000);
      }, 3000);
    }
  };

  return (
    <div className="relative pt-[-40px]">
      {PopUpMessage && (
        <div
          className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ${
            showPopUp ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="w-[20rem] mx-auto px-4 py-2 bg-white rounded-lg shadow-lg">
            {PopUpMessage}
          </div>
        </div>
      )}

      <div className="flex items-center justify-between">
        <div className="w-[8rem] h-[6rem] mx-auto mb-4">
          <img src={logo} alt="logo_image" />
        </div>

        <div className="text-gray-500 cursor-pointer" onClick={togglePop}>
          {" "}
          <IoMdClose size={26} />
        </div>
      </div>

      <h1 className="text-center my-5 text-[26px] font-normal italic text-gray-800">
        Sign In / Sign Up
      </h1>

      <div className="flex gap-5">
        {/* Menu Section */}
        <div className="flex items-center w-full p-4">
          <div className="w-[50%]">
            <button
              onClick={() => handleSectionChange("signin")}
              className={`text-center text-[20px] block p-2 mb-2 w-full ${
                activeSection === "signin"
                  ? " text-gray-900 font-semibold"
                  : "text-gray-400"
              }`}
              style={{ fontFamily: "Lora, serif" }}
            >
              Sign In
            </button>
            <div
              className={`h-1 w-full transition-all duration-300 ${
                activeSection === "signin" ? "bg-[#EDB92B]" : "bg-transparent"
              }`}
            />
          </div>

          <div className="w-[50%]">
            <button
              onClick={() => handleSectionChange("signup")}
              className={`text-center text-[20px] block p-2 mb-2 w-full ${
                activeSection === "signup"
                  ? "text-gray-900 font-semibold"
                  : "text-gray-400"
              }`}
            >
              Sign Up
            </button>

            <div
              className={`h-1 w-full transition-all duration-300 ${
                activeSection === "signup" ? "bg-[#EDB92B]" : "bg-transparent"
              }`}
            />
          </div>
        </div>
      </div>
      {/* Content Section */}
      <div className="relative">
        {activeSection === "signin" && (
          <form
            onSubmit={hadnleSignIn}
            action=""
            className="w-full lg:p-6 mx-auto lg:mt-[-15px]"
          >
            {alertMessage && (
              <div className="w-full mx-auto rounded-lg">
                <div
                  className={`absolute lg:left-[1.5rem] lg:bottom-[11.8rem] text-red-600 bg-white lg:bg-transparent lg:p-0 px-4 py-2 shadow-lg rounded-lg lg:shadow-none lg:rounded-none  text-center  text-md alert ${
                    alertVisible ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-300`}
                >
                  {alertMessage}
                </div>
              </div>
            )}
            <div className="mb-4">
              <label
                className="block mb-1 font-normal text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block mb-1 font-normal text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                placeholder="Enter your password"
                required
              />
            </div>

            <p className="mt-10 mb-4 text-xs text-center text-gray-600">
              By Signing In, I agree to the{" "}
              <a className="text-blue-500" href="/policies">
                Terms of Use
              </a>{" "}
              and{" "}
              <a href="/policies" className="text-blue-500">
                Privacy Policy
              </a>
            </p>

            <button
              type="submit"
              // onClick={handleSignIn}
              className="w-full py-2 mb-10 font-bold text-white transition duration-200 bg-[#E04F1D] rounded-none hover:bg-[#A7330B]"
            >
              CONTINUE
            </button>

            <p className="mt-4 text-sm text-center text-gray-600">
              Don't have an account?{" "}
              <span className="text-[#A7330B]">Click on Sign Up</span>
            </p>
          </form>
        )}

        {activeSection === "signup" && (
          <form
            onSubmit={handleSignUp}
            className="w-full lg:p-6 mx-auto lg:mt-[-15px]"
          >
            {alertMessage && (
              <div className="w-full mx-auto rounded-lg">
                <div
                  className={`absolute lg:left-[1.5rem] lg:bottom-[8.7rem] text-red-600 bg-white lg:bg-transparent lg:p-0 px-4 py-2 shadow-lg rounded-lg lg:shadow-none lg:rounded-none  text-center  text-md alert ${
                    alertVisible ? "opacity-100" : "opacity-0"
                  } transition-opacity duration-300`}
                >
                  {alertMessage}
                </div>
              </div>
            )}
            <div className="flex items-center justify-between w-full gap-4">
              <div className="w-1/2 mb-4">
                <label
                  className="block mb-1 font-normal text-gray-700"
                  htmlFor="firstName"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                  placeholder="first name"
                  required
                />
              </div>

              <div className="w-1/2 mb-4">
                <label
                  className="block mb-1 font-normal text-gray-700"
                  htmlFor="lastName"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                  placeholder="last name"
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block mb-1 font-normal text-gray-700"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block mb-1 font-normal text-gray-700"
                htmlFor="password"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-400 bg-transparent rounded-none focus:outline-[#A7330B]"
                placeholder="Create a password"
                required
              />
            </div>

            <p className="mt-6 mb-4 text-xs text-center text-gray-600">
              By Signing Up, I agree to the{" "}
              <a className="text-blue-500" href="/policies">
                Terms of Use
              </a>{" "}
              and{" "}
              <a className="text-blue-500" href="/policies">
                Privacy Policy
              </a>
            </p>

            <button
              type="submit"
              className="w-full py-2 mb-10 font-bold text-white transition duration-200 bg-[#E04F1D] rounded-none hover:bg-[#A7330B]"
            >
              CONTINUE
            </button>

            <p className="text-sm text-center text-gray-600 mt-[-20px]">
              Already have an account?{" "}
              <span className="text-[#A7330B]">Click on Sign In</span>
            </p>
          </form>
        )}
      </div>
    </div>
  );
};

export default RegisterLogin;
